import { cn } from "@ksoc-private/ui-core";
import { Link } from "@remix-run/react";
import type { FC, HTMLAttributes } from "react";
import IconRad from "~/components/icons/IconRad";

export const AuthenticationFormWrapper = ({
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className="tw-bg-dotted-spacing-12 tw-bg-dotted-zinc-400 tw-bg-zinc-50 tw-flex tw-px-3 tw-min-h-full tw-flex-1 tw-flex-col sm:tw-px-6 lg:tw-px-8 "
      {...props}
    />
  );
};

export const AuthenticationForm: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...other
}) => {
  return (
    <div
      className={cn(
        "tw-space-y-3 tw-shadow-lg tw-ring-1 tw-ring-zinc-100 tw-mt-[10%] sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-md tw-bg-white rounded tw-px-8 tw-pt-8 tw-pb-8",
        className,
      )}
      {...other}
    >
      <div className="tw-flex tw-justify-center">
        <Link to="/">
          <IconRad width={104} height={104} />
        </Link>
      </div>
      {children}
    </div>
  );
};
